import React, { useEffect, useState } from "react";
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from "react-pro-sidebar";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarFooter } from "./SidebarFooter";
import PageTopMenu from "./PageTopMenu";
import { Link } from "react-router-dom";
import Feedback from "./Feedback";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ffffff",
      color: "#607489",
    },
    menu: {
      menuContent: "#fbfcfd",
      icon: "#0098e5",
      hover: {
        backgroundColor: "#c5e4ff",
        color: "#44596e",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: "#2c303b",
      color: "#fff",
    },
    menu: {
      menuContent: "#082440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

// hex to rgba converter
const hexToRgba = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export default function PageLayout({ children, pageTitle, breadcrumbs, header, activeSideMenu }) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [hasImage, setHasImage] = useState(true);
  const [theme, setTheme] = useState("dark");
  const [sidebarWidth, setSidebarWidth] = useState(window.innerWidth >= 997 ? "250px" : "0");

  useEffect(() => {
    setSidebarWidth(broken ? "0px" : collapsed ? "80px" : "250px");
  }, [broken]);

  const handleThemeChange = (e) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  const handleImageChange = (e) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 400,
    },
    icon: {
      fontSize: "18px",
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "#b6b7b9",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0 ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1) : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  const menuItems = [
    {
      id: 1,
      text: "Home",
      link: "/",
      icon: <i className="la la-home"></i>,
    },
    {
      id: 2,
      text: "Orders",
      link: "/sales",
      icon: <i className="la la-briefcase"></i>,
      // subItems: [
      //   {
      //     text: "Place Order",
      //     link: "/placeorder",
      //   },
      //   {
      //     text: "My Orders",
      //     link: "/myorders",
      //   },
      //   {
      //     text: "Store Link Orders",
      //     link: "/storelinkorders",
      //   },
      //   {
      //     text: "VOTM Inventory",
      //     link: "/votminventory",
      //   },
      // ],
    },
    {
      id: 3,
      text: "Enrollment",
      link: "/enrollment",
      icon: <i className="las la-address-card"></i>,
      // subItems: [
      //   {
      //     text: "Enroll New User",
      //     link: "/enrolluser",
      //   },
      //   {
      //     text: "On-going Enrollments",
      //     link: "/ongoingenrollments",
      //   },
      //   {
      //     text: "Generate Link",
      //     link: "/generatelink",
      //   },
      // ],
    },
    { id: 4, text: "My Genealogy", link: "/mygeneology", icon: <i className="las la-project-diagram"></i> },
    { id: 5, text: "Analysis", link: "/analysis", icon: <i className="las la-chart-bar"></i> },
    { id: 6, text: "Reports & Trends", link: "/reportsandtrends", icon: <i className="las la-chart-bar"></i> },
    { id: 7, text: "News Feed", link: "/announcements", icon: <i className="las la-bullhorn"></i> },
    { id: 8, text: "Vmail", link: "/vmail", icon: <i className="las la-envelope"></i> },
    { id: 9, text: "Events", link: "/events",newPresent: true, icon: <i className="las la-calendar"></i>    },
    
    // { id: 9, text: "E-learning", link: "/e-learning", icon: <i className="las la-chalkboard-teacher"></i> },
  ];

  const renderSubMenu = (subMenuItems) => {
    return subMenuItems.map((subMenuItem, index) => {
      if (subMenuItem.subItems) {
        return (
          <SubMenu key={index} label={subMenuItem.text}>
            {renderSubMenu(subMenuItem.subItems)}
          </SubMenu>
        );
      } else {
        return (
          <MenuItem key={index} onClick={() => console.log(`Clicked ${subMenuItem.text}`)}>
            {subMenuItem.text}
          </MenuItem>
        );
      }
    });
  };

  const renderMenu = () => {
    return menuItems.map((menuItem, index) => {
      if (menuItem.subItems) {
        return (
          <SubMenu icon={menuItem.icon} key={index} label={menuItem.text}>
            {renderSubMenu(menuItem.subItems)}
          </SubMenu>
        );
      } else {
        return (
          <Link to={menuItem.link} style={{ color: "#fff" }}>
            <MenuItem
              icon={menuItem.icon}
              key={index}
              className={menuItem.id == activeSideMenu ? "active-menu" : null}
              onClick={() => console.log(`Clicked ${menuItem.text} ${index} ${activeSideMenu}`)}
              suffix={
                menuItem.newPresent && <span className="badge badge-primary text-small ml-1 custom-tada">New</span>
              }
            >
              {menuItem.text}
            </MenuItem>
          </Link>
        );
      }
    });
  };

  const toggleMenu = () => {
    if (broken) {
      setToggled(!toggled);
    } else {
      setCollapsed(!collapsed);
      setSidebarWidth(!collapsed ? "80px" : "250px");
    }
  };

  function ContentHeader() {
    return (
      <div className="content-header-left row">
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <h3 className="content-header-title">{header}</h3>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 flex-end-sm">
          <ol className="breadcrumb">
            {breadcrumbs &&
              breadcrumbs.map((breadcrumb, index) => {
                if (index < breadcrumbs.length - 1) {
                  return (
                    <li className="breadcrumb-item" key={index}>
                      <Link key={index} to={breadcrumb.linkTo}>
                        {breadcrumb.title}
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li className="breadcrumb-item active" key={index}>
                      {breadcrumb.title}
                    </li>
                  );
                }
              })}
          </ol>
        </div>
      </div>
    );
  }

  return (
    <>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        image={require("../assets/app-assets/images/backgrounds/04.jpg")}
        customBreakPoint="997px"
        backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
        rootStyles={{
          color: themes[theme].sidebar.color,
          position: "fixed",
          height: "100%",
          top: "0",
          zIndex: "1050",
          border: "none",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <SidebarHeader style={{ marginBottom: "24px", marginTop: "16px" }} />
          <div style={{ flex: 1, marginBottom: "32px" }}>
            <Menu menuItemStyles={menuItemStyles}>{renderMenu()}</Menu>
          </div>
          {/* <SidebarFooter collapsed={collapsed} /> */}
        </div>
      </Sidebar>
      <div style={{ marginBottom: "16px", marginLeft: sidebarWidth, transition: "margin 0.3s" }}>
        <PageTopMenu onClick={toggleMenu} pageTitle={""}></PageTopMenu>
        <div style={{ padding: "20px 10px", color: "#44596e" }}>
          <div className="container-fluid">
            <Feedback />
            {(header || breadcrumbs) && ContentHeader()}
            {children ?? ""}
          </div>
        </div>
      </div>
    </>
  );
}
