import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import PageLayout from "../../components/PageLayout";
import { usePopper } from "react-popper";
import WishSimpleCard from "../../components/WishSimpleCard";
import PETNodeDetails from "./PETNodeDetails";

export default function PersonalEnrollmentTree() {
  const distributor = JSON.parse(localStorage.getItem("distributor"));

  const containerRef = useRef(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverContent, setPopoverContent] = useState(null);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const [treeData, setTreeData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const [touchStartTime, setTouchStartTime] = useState(null);
  const longPressTimeoutRef = useRef(null);
  // A stack of previous trees for "One level back" navigation
  const [historyStack, setHistoryStack] = useState([]);

  // Configure how many children to show in each chunk
  const CHUNK_SIZE = 10;

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({
    title: "Personal Enrollment Tree",
    linkTo: "/personalenrollmenttree",
  });

  // Initialize Popper for tooltips/popovers
  const { styles: popperStyles, attributes: popperAttributes } = usePopper(referenceElement, popperElement, { placement: "auto" });

  // Detect touch devices
  useEffect(() => {
    const checkTouchDevice = () => {
      setIsTouchDevice("ontouchstart" in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    };
    checkTouchDevice();

    window.addEventListener("resize", checkTouchDevice);
    return () => {
      window.removeEventListener("resize", checkTouchDevice);
    };
  }, []);

  // On mount, fetch data for the logged-in distributor as root
  useEffect(() => {
    fetchDataAndRender(distributor?.distributor_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Rerender the D3 tree whenever treeData changes
  useEffect(() => {
    if (treeData) {
      renderTree(treeData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  // Hide popover if tapping outside on mobile
  useEffect(() => {
    if (!isTouchDevice || !popoverVisible) return;

    const handleTouchOutside = (event) => {
      // Prevent default behavior to avoid any unwanted interactions
      event.preventDefault();

      // Get the SVG container element
      const svgContainer = containerRef.current;

      // Check if the touch target is either:
      // 1. Not inside the popover
      // 2. Not the currently referenced node
      // 3. Not a child of either
      const isOutsidePopover = popperElement && !popperElement.contains(event.target);
      const isOutsideNode = referenceElement && !referenceElement.contains(event.target);

      if (isOutsidePopover && isOutsideNode) {
        setPopoverVisible(false);
        setPopoverContent(null);
        setLongPressTriggered(false);
        setTouchStartTime(null);
      }
    };

    // Add the event listener to the document
    document.addEventListener("touchstart", handleTouchOutside, { passive: false });

    // Also handle regular clicks for hybrid devices
    document.addEventListener("click", handleTouchOutside, { passive: false });

    // Cleanup
    return () => {
      document.removeEventListener("touchstart", handleTouchOutside);
      document.removeEventListener("click", handleTouchOutside);
    };
  }, [isTouchDevice, popoverVisible, popperElement, referenceElement]);

  // ----------------------------------------------------------------
  // 1) Fetch + Render logic (shared with "Back to Root")
  // ----------------------------------------------------------------
  async function fetchDataAndRender(distId) {
    try {
      setLoading(true);
      setError(null);

      const falkorResponse = await fetchData(distId);
      let rootNode = transformFalkorDBToTreeData(falkorResponse);

      // Sort ALL children by gv_sum descending
      sortByGvSumDescRecursively(rootNode);

      // Prune berth subtrees if root != logged-in
      if (rootNode.distId !== distributor.distributor_id) {
        removeBerthSubtrees(rootNode);
      }

      // (A) Root-level chunking: display subsets of top-level children
      rootNode.allChildren = rootNode.children || [];
      rootNode.children = [];
      rootNode.currentChunkIndex = 0;
      rootNode.chunkSize = CHUNK_SIZE;
      showRootChunk(rootNode, 0);

      // (B) For each displayed child, limit deeper levels with the dummy node approach
      rootNode.children.forEach((child) => {
        limitChildrenRecursively(child, CHUNK_SIZE);
      });

      setTreeData(rootNode);

      // If we re-fetch for the logged-in distId, clear history
      if (distId === distributor?.distributor_id) {
        setHistoryStack([]);
      }
    } catch (error) {
      console.error("Error fetching or rendering data:", error);
      setError("Failed to load data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }

  // ----------------------------------------------------------------
  // 2) "Back to Root"
  // ----------------------------------------------------------------
  function handleBackToRoot() {
    fetchDataAndRender(distributor?.distributor_id);
  }

  // ----------------------------------------------------------------
  // 3) "One level back"
  // ----------------------------------------------------------------
  function handleGoBackOneLevel() {
    if (historyStack.length === 0) return;
    const prevTree = historyStack[historyStack.length - 1];
    setHistoryStack(historyStack.slice(0, -1));
    setTreeData(prevTree);
  }

  // ----------------------------------------------------------------
  // 4) Node click => fetch new data
  // ----------------------------------------------------------------
  async function handleNodeClick(clickedDistId) {
    if (!clickedDistId) return; // e.g. if it's a dummy node with no real ID

    try {
      // Save current tree into history
      setHistoryStack((prevStack) => [...prevStack, treeData]);

      setLoading(true);
      setError(null);

      const falkorResponse = await fetchData(clickedDistId);
      let newTreeData = transformFalkorDBToTreeData(falkorResponse);

      // Sort by gv_sum descending
      sortByGvSumDescRecursively(newTreeData);

      // If needed, remove berth subtrees
      // removeBerthSubtrees(newTreeData);

      // Root chunking
      newTreeData.allChildren = newTreeData.children || [];
      newTreeData.children = [];
      newTreeData.currentChunkIndex = 0;
      newTreeData.chunkSize = CHUNK_SIZE;
      showRootChunk(newTreeData, 0);

      // Limit deeper children with dummy node approach
      newTreeData.children.forEach((child) => {
        limitChildrenRecursively(child, CHUNK_SIZE);
      });

      setTreeData(newTreeData);
    } catch (err) {
      console.error("Error in handleNodeClick:", err);
      setError("Failed to update node. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  // ----------------------------------------------------------------
  // 5) Additional Helper Functions
  // ----------------------------------------------------------------
  async function fetchData(distId) {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/enrollment/load-pet-falkor`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        logged_in_distributor_id: distributor?.distributor_id,
        distributor_id: distId,
        depth: 2,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    console.log("Fetched data:", data);
    return data;
  }

  function transformFalkorDBToTreeData(data) {
    const dataArray = data.data;
    const nodeMap = {};
    const hasParent = {};

    dataArray.forEach((item) => {
      const pathNodes = item.path.nodes;
      const pathEdges = item.path.edges;

      // Add nodes to nodeMap
      pathNodes.forEach((node) => {
        const nodeId = node.id;
        if (!nodeMap[nodeId]) {
          nodeMap[nodeId] = {
            id: nodeId,
            name: node.properties.name,
            achievedRankId: node.properties.achievedRankId,
            distId: node.properties.distId || node.properties.distributor_id,
            isBerth: node.properties.isBerth,
            children: [],
            ...node.properties,
          };
        }
      });

      // Build parent-child relationships
      pathEdges.forEach((edge) => {
        const sourceId = edge.sourceId;
        const destinationId = edge.destinationId;

        if (nodeMap[sourceId] && nodeMap[destinationId]) {
          if (!nodeMap[sourceId].children.some((child) => child.id === destinationId)) {
            nodeMap[sourceId].children.push(nodeMap[destinationId]);
            hasParent[destinationId] = true;
          }
        }
      });
    });

    const roots = [];
    for (const nodeId in nodeMap) {
      if (!hasParent[nodeId]) {
        roots.push(nodeMap[nodeId]);
      }
    }

    if (roots.length === 0) {
      throw new Error("Root node not found");
    }

    let finalRoot;
    if (roots.length === 1) {
      finalRoot = roots[0];
    } else {
      finalRoot = {
        id: "root",
        name: "Root",
        distId: "root",
        children: roots,
      };
    }

    return finalRoot;
  }

  /**
   * Recursively sort children by descending gv_sum (i.e. biggest gv_sum first).
   */
  function sortByGvSumDescRecursively(node) {
    if (!node.children || node.children.length === 0) return;

    node.children.sort((a, b) => {
      const aVal = parseFloat(a.gv_sum) || 0;
      const bVal = parseFloat(b.gv_sum) || 0;
      // For descending: bVal - aVal
      return bVal - aVal;
    });

    node.children.forEach(sortByGvSumDescRecursively);
  }

  function removeBerthSubtrees(node) {
    if (!node.children) return;
    node.children = node.children.filter((child) => parseInt(child.isBerth, 10) !== 1);
    node.children.forEach(removeBerthSubtrees);
  }

  /**
   * Shows a chunk of root children [start..end], and marks hasNext/hasPrev accordingly.
   */
  function showRootChunk(node, chunkIndex) {
    if (!node.allChildren) {
      node.children = [];
      node.hasNext = false;
      node.hasPrev = false;
      return;
    }

    const start = chunkIndex * node.chunkSize;
    const end = start + node.chunkSize;
    const chunkChildren = node.allChildren.slice(start, end);

    node.children = chunkChildren;
    node.currentChunkIndex = chunkIndex;
    node.hasPrev = chunkIndex > 0;
    node.hasNext = end < node.allChildren.length;
  }

  /**
   * Recursively limit any node's children to chunkSize.
   * If the node has > chunkSize children, keep the first chunkSize - 1
   * and then add a dummy node labeled "+ X more children..." at the END.
   */
  function limitChildrenRecursively(node, chunkSize) {
    if (!node.children || node.children.length === 0) return;

    // By now, they're already sorted by gv_sum descending in `sortByGvSumDescRecursively`.
    // Just check if we exceed chunkSize, then add a dummy node.
    if (node.children.length > chunkSize) {
      const visible = node.children.slice(0, chunkSize - 1);
      const hidden = node.children.slice(chunkSize - 1);
      const hiddenCount = hidden.length;

      // Build a dummy child object
      const dummyChild = {
        id: `dummy-${node.id}`,
        name: `+ ${hiddenCount} more children...`,
        distId: null, // no real ID
        isDummy: true,
        children: [],
      };

      // Final children = the first (chunkSize - 1) plus the dummy node at the end
      node.children = [...visible, dummyChild];
    }

    // Recurse on any real children
    node.children.forEach((child) => {
      if (!child.isDummy) {
        limitChildrenRecursively(child, chunkSize);
      }
    });
  }

  function handleViewNextChunk() {
    const rootNode = treeData;
    if (!rootNode || !rootNode.hasNext) return;
    const newIndex = rootNode.currentChunkIndex + 1;
    showRootChunk(rootNode, newIndex);

    // Re-apply the dummy node logic for newly displayed children
    rootNode.children.forEach((child) => {
      limitChildrenRecursively(child, CHUNK_SIZE);
    });

    setTreeData({ ...rootNode });
  }

  function handleViewPrevChunk() {
    const rootNode = treeData;
    if (!rootNode || !rootNode.hasPrev) return;
    const newIndex = rootNode.currentChunkIndex - 1;
    showRootChunk(rootNode, newIndex);

    // Re-apply the dummy node logic
    rootNode.children.forEach((child) => {
      limitChildrenRecursively(child, CHUNK_SIZE);
    });

    setTreeData({ ...rootNode });
  }

  // ----------------------------------------------------------------
  // 6) The main rendering of the D3 tree
  // ----------------------------------------------------------------
  function renderTree(rootData) {
    const svg = d3.select(containerRef.current);
    svg.selectAll("*").remove();

    const width = Math.max(containerRef.current.clientWidth, 600);
    const height = containerRef.current.clientHeight || 600;

    // Convert raw object to a hierarchy
    const root = d3.hierarchy(rootData, (d) => d.children);

    const dx = 50;
    const dy = width / (root.height + 2);
    const treeLayout = d3.tree().nodeSize([dx, dy]);
    treeLayout(root);

    let x0 = Infinity;
    let x1 = -x0;
    root.each((d) => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });
    const adjustedHeight = x1 - x0 + dx * 2;

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-dy, x0, width, adjustedHeight])
      .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif; overflow: hidden;");

    const g = svg.append("g");

    // Links
    g.append("g")
      .attr("fill", "none")
      .attr("stroke", "#555")
      .attr("stroke-opacity", 0.4)
      .attr("stroke-width", 1.5)
      .selectAll("path")
      .data(root.links())
      .join("path")
      .attr(
        "d",
        d3
          .linkHorizontal()
          .x((d) => d.y)
          .y((d) => d.x)
      );

    // Clip path for images
    svg.append("defs").append("clipPath").attr("id", "circle-clip").append("circle").attr("r", 20).attr("cx", 0).attr("cy", 0);

    // Nodes
    const nodeSelection = g
      .append("g")
      .attr("stroke-linejoin", "round")
      .attr("stroke-width", 3)
      .selectAll("g")
      .data(root.descendants())
      .join("g")
      .attr("transform", (d) => `translate(${d.y},${d.x})`);

    // Hover (desktop)
    if (!isTouchDevice) {
      nodeSelection
        .on("mouseover", function (event, d) {
          // If it's a dummy node, skip showing the popover
          if (d.data.isDummy) return;
          setReferenceElement(this);
          setPopoverContent(d.data);
          setPopoverVisible(true);
        })
        .on("mouseout", () => {
          setPopoverVisible(false);
        })
        .on("click", function (event, d) {
          if (d.data.isDummy) {
            console.log("Dummy node clicked:", d.data);
            return;
          }
          handleNodeClick(d.data.distId);
        });
    }
    // Mobile behavior (touch interactions)
    else {
      nodeSelection
        .on("touchstart", function (event, d) {
          event.preventDefault();
          const touchTime = new Date().getTime();
          setTouchStartTime(touchTime);

          // Clear any existing timeout
          if (longPressTimeoutRef.current) {
            clearTimeout(longPressTimeoutRef.current);
          }

          // Set up long press detection
          longPressTimeoutRef.current = setTimeout(() => {
            if (!d.data.isDummy) {
              setReferenceElement(this);
              setPopoverContent(d.data);
              setPopoverVisible(true);
              setLongPressTriggered(true);
            }
          }, 600); // 600ms for long press

          const handleTouchMove = () => {
            // Cancel long press if finger moves
            clearTimeout(longPressTimeoutRef.current);
            setTouchStartTime(null);
          };

          const handleTouchEnd = (endEvent) => {
            clearTimeout(longPressTimeoutRef.current);
            const touchEndTime = new Date().getTime();
            const touchDuration = touchEndTime - touchTime;

            // Clean up listeners
            this.removeEventListener("touchmove", handleTouchMove);
            this.removeEventListener("touchend", handleTouchEnd);

            // Only trigger click for short touches (< 200ms) and when no long press occurred
            if (touchDuration < 200 && !longPressTriggered) {
              if (!d.data.isDummy) {
                handleNodeClick(d.data.distId);
              }
            }
          };

          this.addEventListener("touchmove", handleTouchMove);
          this.addEventListener("touchend", handleTouchEnd);
        })
        .on("touchcancel", () => {
          clearTimeout(longPressTimeoutRef.current);
          setTouchStartTime(null);
          setLongPressTriggered(false);
          setPopoverVisible(false);
        });
    }

    // Normal click
    nodeSelection.on("click", function (event, d) {
      // If a long press triggered, we skip normal click
      if (longPressTriggered) {
        setLongPressTriggered(false);
        return;
      }

      if (d.data.isDummy) {
        // For now, just show a console or do something
        console.log("Dummy node clicked:", d.data);
        return;
      }

      // Otherwise, normal node flow
      handleNodeClick(d.data.distId);
    });

    // Node images
    nodeSelection
      .append("image")
      .attr("xlink:href", (d) =>
        d.data.isDummy
          ? // a different icon for dummy nodes, if you like
            "../assets/app-assets/images/badges/default.png"
          : `../assets/app-assets/images/badges/${getRankBadgeImage(d.data.achievedRankId)}`
      )
      .attr("width", 40)
      .attr("height", 40)
      .attr("x", -20)
      .attr("y", -20)
      .attr("clip-path", "url(#circle-clip)")
      .on("error", function () {
        d3.select(this).attr("xlink:href", "../assets/app-assets/images/badges/default.png");
      });

    // Node labels
    const labels = nodeSelection.append("g").attr("class", "label");
    const text = labels
      .append("text")
      .attr("dy", "0.31em")
      .attr("x", (d) => (d.depth === 0 ? -30 : 30))
      .attr("text-anchor", (d) => (d.depth === 0 ? "end" : "start"))
      .style("font-size", "10px")
      .text((d) => d.data.name);

    // Background for label
    text.each(function () {
      const bbox = this.getBBox();
      d3.select(this.parentNode)
        .insert("rect", "text")
        .attr("x", bbox.x - 4)
        .attr("y", bbox.y - 4)
        .attr("width", bbox.width + 8)
        .attr("height", bbox.height + 8)
        .attr("fill", "white")
        .attr("opacity", 0.8)
        .attr("rx", 4)
        .attr("ry", 4);
    });

    // Zoom & pan
    const zoom = d3
      .zoom()
      .scaleExtent([1, 50])
      .on("zoom", (event) => {
        g.attr("transform", event.transform);
      })
      .filter((event) => {
        // Always allow mouse interactions on desktop
        if (!isTouchDevice) {
          return true;
        }

        // For touch devices:
        if (event.type === "touchstart" || event.type === "touchmove" || event.type === "touchend") {
          // Only allow if there are 2 or more touches
          return event.touches && event.touches.length >= 2;
        }

        // Allow wheel events for pinch-zoom
        if (event.type === "wheel") {
          return true;
        }

        return false;
      });

    svg.call(zoom);

    // Set initial transform
    svg.call(zoom.transform, d3.zoomIdentity.scale(1));

    // Prevent default touch behavior to avoid conflicts
    svg.on(
      "touchstart",
      (event) => {
        if (event.touches.length >= 2) {
          event.preventDefault();
        }
      },
      { passive: false }
    );
  }

  function getRankBadgeImage(achievedRankId) {
    const ranks = JSON.parse(localStorage.getItem("ranks")) || [];
    const foundRank = ranks.find((x) => parseInt(x.id) === parseInt(achievedRankId));
    return foundRank ? `${foundRank.title}.png` : "default.png";
  }

  // ----------------------------------------------------------------
  //             Component UI
  // ----------------------------------------------------------------
  return (
    // <PageLayout activeSideMenu="6" pageTitle="Reports & Trends" header="Personal Enrollment Tree" breadcrumbs={breadcrumbs}>
    <WishSimpleCard
      header={
        <h5>
          Personal Enrollment Tree<span className="badge badge-primary text-small ml-1 custom-tada">New</span>
        </h5>
      }
      className="rounded-1 border-light"
      changeBorder={false}
    >
      {loading && <div style={{ textAlign: "center", padding: "20px" }}>Loading...</div>}
      {error && <div style={{ color: "red", padding: "20px", textAlign: "center" }}>{error}</div>}
      {!loading && !error && isTouchDevice && <div className="pet-pagination-text">Use 2 fingers to zoom and pan tree.</div>}

      {/* 
          (A) Conditionally show "Back to Root" only if 
              treeData.distId !== logged_in_distributor_id.
          (B) Always show "One level back" if we have history.
        */}
      {!loading && (
        <div className="d-flex justify-content-space-between" style={{ marginBottom: "10px" }}>
          {historyStack.length > 0 && (
            <button className="text-primary" onClick={handleGoBackOneLevel}>
              <i className="las la-angle-left"></i>&nbsp;One Level Back
            </button>
          )}
          {treeData?.distId !== distributor?.distributor_id && treeData && (
            <button className="text-primary" onClick={handleBackToRoot}>
              <i className="las la-arrow-up"></i>&nbsp;Back to root
            </button>
          )}
        </div>
      )}

      {!loading && !error && treeData && (
        <>
          <svg ref={containerRef} id="treeContainer" style={{ width: "100%" }} />

          {/* 
              If rootNode has an allChildren array, show the chunk info 
              e.g. "Showing 1 to 10 of [Name]'s 192 children" 
            */}
          {treeData.allChildren && treeData.allChildren.length > 0 && (
            <div className="pet-pagination-text">
              {(() => {
                const startIndex = treeData.currentChunkIndex * treeData.chunkSize + 1;
                const endIndex = Math.min(treeData.allChildren.length, startIndex + treeData.chunkSize - 1);
                return `Showing ${startIndex} to ${endIndex} of ${treeData.name}'s ${treeData.allChildren.length} Children`;
              })()}
            </div>
          )}

          {/* Chunk navigation (only at root) */}
          <div className="pet-page-buttons" style={{ marginBottom: "10px", textAlign: "right" }}>
            {treeData.hasPrev && (
              <button className="btn btn-sm btn-link p-0" style={{ marginRight: 10, fontSize: "11px" }} onClick={handleViewPrevChunk}>
                Previous
              </button>
            )}
            {treeData.hasNext && (
              <button className="btn btn-sm btn-link p-0" style={{ fontSize: "11px" }} onClick={handleViewNextChunk}>
                Next
              </button>
            )}
          </div>

          {/* Desktop Popover */}
          {!isTouchDevice && popoverVisible && popoverContent && (
            <div
              ref={setPopperElement}
              style={{
                ...popperStyles.popper,
                zIndex: 10,
                pointerEvents: "none",
              }}
              {...popperAttributes.popper}
            >
              <div className="pet-node-details-wrapper">
                <PETNodeDetails details={popoverContent} />
              </div>
            </div>
          )}

          {/* Mobile Popover (Long Press) */}
          {isTouchDevice && popoverVisible && popoverContent && (
            <div
              ref={setPopperElement}
              style={{
                ...popperStyles.popper,
                zIndex: 10,
                pointerEvents: "none",
              }}
              {...popperAttributes.popper}
            >
              <div className="pet-node-details-wrapper">
                <PETNodeDetails details={popoverContent} />
              </div>
            </div>
          )}
        </>
      )}
    </WishSimpleCard>
    // </PageLayout>
  );
}
