import React from "react";
import PageLayout from "../../components/PageLayout";
import { FirstOrderTrendOptions, RankTrendOptions, ReOrderTrendOptions, VolumeTrendOptions } from "../Reports/TrendsConfig";
import WishCard from "../../components/WishCard";
import EmptyAnimation from "../../components/EmptyAnimation";
import animation from "../../assets/app-assets/images/lottie/NoDataAvailable.json";

export default function EventsDash() {
  const distributor = JSON.parse(localStorage.getItem("distributor"));

  const breadcrumbs = [];
  breadcrumbs.push({ title: "Home", linkTo: "/" });
  breadcrumbs.push({ title: "Events", linkTo: "/events" });

  const publicReports = [
    {
      title: "Events",
      icon: "la-chart-line",
      line1: "View the ongoing events and register.",
      linkTo: "/eventlist",
      linkToState: {},
    },
  ];
 
  

  const reportsNotYetReleased = [];
  return (
    <PageLayout activeSideMenu="9" pageTitle="Events" header="Events" breadcrumbs={breadcrumbs}>
      <section className="row">
        {publicReports.map((card, index) => (
          <div className="col-md-4 col-sm-6" key={index}>
            <WishCard
              title={card.title}
              icon={card.icon}
              line1={card.line1}
              line2={card.line2}
              linkToState={card.linkToState}
              linkTo={card.linkTo}
              maxHeight={true}
              linkToModal={card.linkToModal}
            ></WishCard>
          </div>
        ))}
       
      </section>
    </PageLayout>
  );
}
