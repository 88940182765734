// src/components/PETNodeDetails.js
import React from "react";
import data from "../../data/Data.json"; // Adjust the path based on your project structure

export default function PETNodeDetails({ details }) {
  // Retrieve ranks and distributor from localStorage
  const ranks = JSON.parse(localStorage.getItem("ranks")) || [];
  const distributor = JSON.parse(localStorage.getItem("distributor"));

  // Helper functions to get titles based on IDs
  const getStatusTitle = (id) => {
    const status = data.activityStatuses.find((item) => item.activity_status_id === id);
    return status ? status.activity_status_title : "Unknown"; // Default to 'Unknown' if ID doesn't match
  };

  const getRankTitle = (id) => {
    const rank = ranks.find((item) => parseInt(item.id) === parseInt(id));
    return rank ? rank.title : "Unknown"; // Default to 'Unknown' if ID doesn't match
  };

  return (
    <table border="1" width="100%" id="genealogy-hover-table" className="table table-borderless m-0">
      <tbody>
        <tr>
          <td colSpan="6">
            <h6 className="text-center">
              {details?.name} [{details?.distId}]&nbsp;&nbsp;
            </h6>
            Status: {getStatusTitle(details?.activityStatus)}
          </td>
        </tr>
        <tr>
          <td colSpan="6">Achieved Rank: {getRankTitle(details?.achievedRankId)}</td>
        </tr>
        {/* <tr>
          <td colSpan="6">Is berth: {details?.isBerth}</td>
        </tr> */}
        <tr>
          <td colSpan="6">
            <strong>Volume from PET: {details?.gv_sum ?? "N/A"}</strong>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
