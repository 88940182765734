import { useState, useEffect } from "react";
import WishToaster from "../components/WishToaster";
import { AppUtils } from "../services/AppUtils";
import useAPIs from "./useAPIs";

export const PersonalDetails = {
  distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
  section_level: 1,
  dist_temp_id: "",
  title_id: 1,
  first_name: (String, ""),
  second_name: (String, ""),
  dob: (String, ""),
  language_id: 1,
  gender_id: 1,
  marital_status_id: 1,
  profession_id: 1,
  monthly_income_id: 1,
  aadhar_no: (String, ""),
  aadhar_file: (String, ""),
  pan_no: (String, ""),
  pan_file: (String, ""),
  gst_no: (String, ""),
  pan_declaration: 0,
  gst_declaration: 0,
  student_id_file: (String, ""),
};

export const ContactDetails = {
  distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
  section_level: 2,
  dist_temp_id: "",
  email: "",
  phone: "",
  permanent_address_line1: "",
  permanent_address_line2: "",
  permanent_postalcode_id: "",
  permanent_postalcode: "",
  permanent_post_name: "",
  permanent_city_id: "",
  permanent_district_id: "",
  permanent_state_id: "",
  permanent_country_id: "",
  permanent_city_name: "",
  permanent_district_name: "",
  permanent_state_name: "",
  permanent_country_name: "",
  is_addresses_same: 1,
  communication_address_line1: "",
  communication_address_line2: "",
  communication_postalcode_id: "",
  communication_postalcode: "",
  communication_post_name: "",
  communication_city_id: "",
  communication_district_id: "",
  communication_state_id: "",
  communication_country_id: "",
  communication_city_name: "",
  communication_district_name: "",
  communication_state_name: "",
  communication_country_name: "",
  address_proof_file: "",
};

export const BankDetails = {
  distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
  section_level: 3,
  dist_temp_id: "",
  bank_name: "",
  branch_name: "",
  ifsc: "",
  account_no: "",
  confirm_account_no: "",
  account_name: "",
  bank_id: 1,
  bank_proof_file: "",
};

export const CoAppDetails = {
  distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
  section_level: 4,
  dist_temp_id: "",
  coapplicant_name: "",
  coapplicant_phone: "",
  coapplicant_email: "",
  coapplicant_dob: "",
  coapplicant_gender_id: 1,
  coapplicant_relationship_id: "",
  coapplicant_aadhar_no: "",
  coapplicant_pan_no: "",
  coapplicant_bank_name: "",
  coapplicant_branch_name: "",
  coapplicant_ifsc: "",
  coapplicant_account_no: "",
  coapplicant_confirm_account_no: "",
  coapplicant_account_name: "",
  coapplicant_bank_id: 1,
  nominee: "",
  nominee_mrelationship_id: "",
  coapp_aadhar_file: "",
  coapp_pan_file: "",
  coapp_bank_proof_file: "",
};

export const PlacementDetails = {
  distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
  placement_distributor_id: "",
  placement_position_id: "",
  sponsor_distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
  sponsor_distributor_name: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.name : null,
  password: "",
  confirm_password: "",
};

const useEnrollment = (language_id) => {
  const { apiError, postData, postFormData, getData } = useAPIs();
  const [enrollmentMasterData, setEnrollmentMasterData] = useState(null);
  const [enrollmentError, setError] = useState(null);
  const [terms, setTerms] = useState({});
  const [enrollmentLoading, setLoading] = useState(false);
  const [locationDetails, setLocationDetaills] = useState(null);
  const [tempDistId, setTempDistId] = useState("");
  const [currentSection, setCurrentSection] = useState(1);

  useEffect(() => {
    setError(apiError);
  }, [apiError]);

  useEffect(() => {
    if (!enrollmentMasterData) {
      getData("/enrollment/fetch-enrolment-form-master-data", (data) => {
        setEnrollmentMasterData(data);
      });
    }
  }, []);

  useEffect(() => {
    if (language_id) {
      getData(`/enrollment/fetch-terms-and-conditions?language_id=${language_id}`, (data) => {
        setTerms(data);
      });
    }
  }, [language_id]);

  useEffect(() => {
    if (enrollmentError) {
      //setLoading(false);
    }
  }, [enrollmentError]);

  const getLocationDetails = (pincode, onSuccess) => {
    //setLoading(true);
    getData(`/sales/location/list/${pincode}`, (locationDetails) => {
      //setLoading(false);
      setLocationDetaills(locationDetails);
      onSuccess(locationDetails);
    });
  };
  const postRequest = (url, payload, onSuccess, onError) => {
    setLoading(true);
    postData(
      url,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
        setLoading(false);
      },
      (error) => {
        onError && onError(error);
        setLoading(false);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const getPositions = (payload, onSuccess, onError) => {
    postRequest("/enrollment/fetch-available-position", payload, onSuccess, onError);
  };
  const validatePostion = (payload, onSuccess, onError) => {
    postRequest("/enrollment/validate-enrollment-position", payload, onSuccess, onError);
  };
  const enrollmentLinks = (payload, onSuccess, onError) => {
    postRequest("/enrollment/fetch-dist-enrollment-links", payload, onSuccess, onError);
  };
  const generateEnrollmentLink = (payload, onSuccess, onError) => {
    postRequest("/enrollment/generate-enrollment-link", payload, onSuccess, onError);
  };
  const deleteEnrollmentLink = (payload, onSuccess, onError) => {
    postRequest("/enrollment/delete-enrollment-link", payload, onSuccess, onError);
  };
  const deleteTempEnrollment = (payload, onSuccess, onError) => {
    postRequest("/enrollment/delete-temp-enrollee-details", payload, onSuccess, onError);
  };
  const getPendingEnrollments = (payload, onSuccess, onError) => {
    postRequest("/enrollment/fetch-pending-enrollee-list", payload, onSuccess, onError);
  };
  const saveEnrolleeDetails = (pageNumber, payload, onSuccess) => {
    setLoading(true);
    let formData;
    const pages = ["personal-details", "contact-details", "bank-details", "coapp-details"];
    switch (pageNumber) {
      case 0:
        payload.basicDetails.pan_declaration = payload.basicDetails.pan_declaration ? 1 : 0;
        payload.basicDetails.gst_declaration = payload.basicDetails.gst_declaration ? 1 : 0;
        payload.basicDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.basicDetails);
        break;
      case 1:
        payload.contactDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.contactDetails);
        break;
      case 2:
        payload.bankDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.bankDetails);
        break;
      case 3:
        payload.coApplicantDetails.dist_temp_id = tempDistId ? tempDistId : "";
        formData = AppUtils.createFormData(payload.coApplicantDetails);
        break;
      default:
        break;
    }

    let savesection = {
      distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
      dist_temp_id: tempDistId,
      section_level: currentSection,
    };
    //alert(personalDetailsFormData.get("marital_status_id"));
    if (pageNumber < 4) {
      postFormData(
        `/enrollment/store-temp-${pages[pageNumber]}`,
        formData,
        (data) => {
          //alert(data.dist_temp_id);
          if (pageNumber === 0) {
            setTempDistId(data.dist_temp_id);
            savesection.dist_temp_id = data.dist_temp_id;
            saveSectionDone(pageNumber, savesection);
          } else {
            saveSectionDone(pageNumber, savesection);
          }
          onSuccess && onSuccess(data);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      onSuccess();
      setLoading(false);
      saveSectionDone(pageNumber, savesection);
    }
  };

  const saveSectionDone = (pageNumber, savesection) => {
    console.log(pageNumber, currentSection);
    if (savesection.dist_temp_id && pageNumber < 5 && pageNumber === currentSection - 1) {
      postData(`/enrollment/store-temp-enrollee-section-level`, savesection, (data) => {
        console.log(data);
        setCurrentSection(currentSection + 1);
      });
    }
  };

  const getBankBranchDetails = (ifscCode, onSuccess) => {
    getData(`/enrollment/fetch-bank-details?ifsc_code=${ifscCode}`, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const getCoAppRelationships = (genderId, maritialStatusId, onSuccess) => {
    getData(`/enrollment/fetch-coapp-relationships-list?gender_id=${genderId}&marital_status_id=${maritialStatusId}`, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const acceptTerms = (payload = {}, onSuccess) => {
    payload.dist_temp_id = tempDistId;
    payload.distributor_id = localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null;
    postData(`/enrollment/accept-terms-and-conditions`, payload, (data) => {
      onSuccess && onSuccess(data);
    });
  };
  const downloadTerms = () => {
    let payload = {};
    payload.dist_temp_id = tempDistId;
    payload.distributor_id = localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null;
    postData(
      `/enrollment/download-dist-contract`,
      payload,
      (data) => {
        window.open(data.contract_file_url);
      },
      (error) => {
        WishToaster({
          toastMessage: "Something went wrong!",
          toastType: "error",
        });
      }
    );
  };

  const enrollUser = (payload, onSuccess, onError) => {
    payload.dist_temp_id = tempDistId;
    setLoading(true);
    postData(
      `/enrollment/create-new-distributor`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
        setLoading(false);
      },
      (error) => {
        onError && onError(error);
        setLoading(false);
        WishToaster({
          toastMessage: error ? error : "Something went wrong!",
          toastType: "error",
        });
      }
    );
  };
  const fetchEnrolleeDetails = (dist_temp_id, onSuccess, onError) => {
    postData(
      `/enrollment/fetch-temp-enrollee-details`,
      {
        distributor_id: localStorage.getItem("distributor") ? JSON.parse(localStorage.getItem("distributor"))?.distributor_id : null,
        dist_temp_id: dist_temp_id,
      },
      (data) => {
        setTempDistId(data.dist_temp_id);
        setCurrentSection(data.section_level + 1);
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const checkPhone = async (phone, onSuccess, onError) => {
    await postData(
      `/enrollment/phone-verify`,
      {
        phone: phone,
      },
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const checkEmail = async (email, onSuccess, onError) => {
    await postData(
      `/enrollment/email-verify`,
      {
        email: email,
      },
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const checkPAN = async (pan, onSuccess, onError) => {
    await postData(
      `/enrollment/pan-validation`,
      {
        pan_no: pan,
      },
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
      }
    );
  };
  const validateSponsor = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/validate-sponsor`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };
  const deleteInactiveDistributor = (payload, onSuccess, onError) => {
    postRequest("/enrollment/deleteInactiveDistWOFirstOrder", payload, onSuccess, onError);
  };

  const updatePosition = (payload, onSuccess, onError) => {
    postData(
      `/enrollment/update-placement-position`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };

  const uploadVideoStatus = (payload, onSuccess, onError) => {
    postData(
      `enrollment/video-status-details`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };

  const postFeedback = (payload, onSuccess, onError) => {
    payload.distributor_id = JSON.parse(localStorage.getItem("distributor"))?.distributor_id;
    postData(
      `/reports/feedback`,
      payload,
      (data) => {
        onSuccess && onSuccess(data);
        WishToaster({
          toastMessage: "Thank you for the feedback.",
          toastType: "success",
        });
      },
      (error) => {
        onError && onError(error);
        WishToaster({
          toastMessage: error,
          toastType: "error",
        });
      }
    );
  };

  return {
    enrollmentError,
    enrollmentLoading,
    enrollmentMasterData,
    terms,
    getLocationDetails,
    locationDetails,
    getPendingEnrollments,
    enrollmentLinks,
    saveEnrolleeDetails,
    tempDistId,
    getBankBranchDetails,
    getCoAppRelationships,
    acceptTerms,
    downloadTerms,
    getPositions,
    validatePostion,
    enrollUser,
    generateEnrollmentLink,
    fetchEnrolleeDetails,
    deleteEnrollmentLink,
    deleteTempEnrollment,
    checkPhone,
    checkEmail,
    checkPAN,
    validateSponsor,
    deleteInactiveDistributor,
    updatePosition,
    uploadVideoStatus,
    postFeedback,
  };
};

export default useEnrollment;
