import React, { useState } from "react";
import WishSimpleCard from "../../../components/WishSimpleCard";

const DistCounts = ({ downlineCount }) => {
  // Calculate total distributors
  const totalDistributors = downlineCount?.firstOrgCount + downlineCount?.secondOrgCount;

  // Calculate percentage of first vs second org
  const firstOrgPercentage =
    totalDistributors > 0 ? Math.round((downlineCount?.firstOrgCount / totalDistributors) * 100) : 0;
  const secondOrgPercentage =
    totalDistributors > 0 ? Math.round((downlineCount?.secondOrgCount / totalDistributors) * 100) : 0;

  // Custom styles
  const numberCircleStyle = {
    width: "36px",
    height: "36px",
    marginRight: "10px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "bold",
    color: "white",
  };

  const purpleStyle = {
    backgroundColor: "#6f42c1",
  };

  const purpleBadgeStyle = {
    backgroundColor: "rgba(111, 66, 193, 0.1)",
    color: "#6f42c1",
  };

  return (
    <WishSimpleCard>
      <div className="mb-1">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="fs-4 fw-bold m-0">Network Performance</h4>
        </div>
        <label className="text-muted mb-0">Star-Ranked Distributor Overview</label>
      </div>

      {/* Total Count Card */}
      <div className="card mb-1 shadow-sm">
        <div className="card-body d-flex align-items-center">
          <div className="bg-primary bg-opacity-10 p-3 rounded-circle mr-1">
            <i className="bi bi-people fs-4 text-primary"></i>
          </div>
          <div>
            <p className="text-muted mb-0">Total Star-Ranked Distributors</p>
            <h2 className="fs-2 fw-bold">{totalDistributors.toLocaleString()}</h2>
          </div>
        </div>
      </div>

      {/* Organization Cards */}
      <div className="row g-3">
        {/* First Org Card */}
        <div className="col-md-6">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div style={{ ...numberCircleStyle, backgroundColor: "#0d6efd" }} className="me-2">
                    1
                  </div>
                  <h5 className="mb-0">First Organization</h5>
                </div>
                <span className="badge text-primary" style={purpleBadgeStyle}>
                  {firstOrgPercentage}%
                </span>
              </div>
              <h3 className="fw-bold mb-2">{downlineCount?.firstOrgCount.toLocaleString()}</h3>
              <div className="progress" style={{ height: "8px" }}>
                <div
                  className="progress-bar bg-primary"
                  role="progressbar"
                  style={{ width: `${firstOrgPercentage}%` }}
                  aria-valuenow={firstOrgPercentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>

        {/* Second Org Card */}
        <div className="col-md-6">
          <div className="card h-100 shadow-sm">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="d-flex align-items-center">
                  <div style={{ ...numberCircleStyle, ...purpleStyle }} className="me-2">
                    2
                  </div>
                  <h5 className="mb-0">Second Organization</h5>
                </div>
                <span className="badge" style={purpleBadgeStyle}>
                  {secondOrgPercentage}%
                </span>
              </div>
              <h3 className="fw-bold mb-2">{downlineCount?.secondOrgCount.toLocaleString()}</h3>
              <div className="progress" style={{ height: "8px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: `${secondOrgPercentage}%`, ...purpleStyle }}
                  aria-valuenow={secondOrgPercentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-muted mt-1 text-end small">
        <i className="bi bi-info-circle me-1"></i>
        Last updated: {new Date().toLocaleTimeString()}
      </div>
    </WishSimpleCard>
  );
};

export default DistCounts;
